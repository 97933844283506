import React, {useEffect} from "react";
import {RemixBrowser, useLocation, useMatches} from "@remix-run/react";
import {init, BrowserTracing, remixRouterInstrumentation} from "@sentry/remix";
import {hydrate} from "react-dom";
import {SENTRY_DSN} from "./constants/SentryDSN";

//Sentry.io should be enabled on both production and staging environments
//On client side we must check by NODE_ENV (injected via remix)
if (process.env.NODE_ENV !== "development") {
    init({
        dsn: SENTRY_DSN,
        tracesSampleRate: 1,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: remixRouterInstrumentation(
                    useEffect,
                    useLocation,
                    useMatches
                )
            })
        ]
    });
}

hydrate(<RemixBrowser />, document);
